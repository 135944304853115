<template>
  <div class="tab-host">
    <v-tabs
      v-model="tab"
      fixed-tabs
      color="white"
      background-color="cmpbk_lght"
    >
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item v-for="item in items" :key="item.tab" class="cmpbk_lght">
        <component v-bind:is="item.content"></component>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<style lang="scss" scoped>
.tab-host {
  max-width: 1440px;
  margin: 0 auto;
}
.v-tab {
  color: darkgray !important;
  background-color: black;
}
.v-tab.v-tab--active {
  color: white !important;
  background-color: #363c43;
  border-left: 3px solid rgb(125, 125, 126);
  border-right: 3px solid rgb(125, 125, 126);
  border-top: 3px solid rgb(125, 125, 126);
  border-bottom: 3px solid rgb(0, 0, 0);
}
</style>

<script>
import releases from "./releases";
import subscriptions from "./subscriptions";
export default {
  components: {
    releases,
    subscriptions,
  },
  data() {
    return {
      name: "xtabs",
      tab: null,
      items: [
        { tab: "releases", content: "releases" },
        { tab: "subscriptions", content: "subscriptions" },
      ],
    };
  },
};
</script>
